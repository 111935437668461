import { DayOfWeek } from '@fluentui/react';

export const dateTimeFormat = {
  stringDateFormat: 'dd.mm.yyyy',
  momentDateFormat: 'DD.MM.YYYY',
  momentDateTimeFormat: 'DD.MM.YYYY HH:mm',
  momentDateDatabaseFormat: 'YYYY-MM-DD',
  momentDateDashFormat: 'DD-MM-YYYY',
  momentDateTimeDashFormat: 'YYYY-MM-DD HH:mm:s',
  printDateTimeFormat: 'DD/MM/YYYY, hh:mm A',
  firstDayOfWeek: DayOfWeek.Monday,
};
