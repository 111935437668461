import { useTranslation } from 'react-i18next';
import { UpgradeToOnixWorkConstants } from '../../constants/common.constants';
import { showMessageBox } from '../../services/modal.service';
import { MessageBoxProps } from '../../types/modal.type';
import Title from '../ui/Title/Title';

export interface IShowUpgradeOWMessageProps {
  onClickUpgradeToOWLink?: () => void;
}

export const useShowUpgradeOWMessage = (props?: IShowUpgradeOWMessageProps) => {
  const { onClickUpgradeToOWLink } = props || {};

  const [translate] = useTranslation();

  const findAndRegisterUpgradeToOWLinkEvent = (element: HTMLDivElement | null) => {
    if (!element) {
      return;
    }

    const links = element.querySelectorAll(`a`);

    links.forEach((link) => {
      link.addEventListener('click', () => {
        onClickUpgradeToOWLink?.();
      });
    });
  };

  const upgradeBox = {
    header: <Title text={translate('CommonResource.CannotPerformAction')} />,
    children: (
      <div
        className={`w-auto ${UpgradeToOnixWorkConstants.wrapperClassName}`}
        ref={findAndRegisterUpgradeToOWLinkEvent}
        dangerouslySetInnerHTML={{ __html: translate('CaptionResource.UpgradeExpressToOWMessage') }}
      ></div>
    ),
  } as MessageBoxProps;

  const showUpgradeOWMessage = () => {
    showMessageBox(upgradeBox);
  };

  return { showUpgradeOWMessage };
};
