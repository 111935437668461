import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowUpgradeOWMessage } from '../../common/hooks/useShowUpgradeOWMessage';
import { useTracking } from '../../common/hooks/useTracking';
import { Modules } from '../../constants/modules.constants';
import { TrackingEvent } from '../../constants/tracking-event.constant';
import { WidgetTemplateId } from '../../enums/widget.enum';
import { DashboardHelper } from '../../helpers/dashboard-helper';
import { WidgetHelper } from '../../helpers/widget-helper';
import { DashboardLayoutItem } from '../../models/dashboard/dashboard.model';
import { WidgetData } from '../../models/dashboard/widget.model';
import { DashboardService } from '../../services/dashboard.service';
import { getLanguageId } from '../../utils/localStorage.utils';
import { WidgetChartRenderer, WidgetChartRendererProps } from './WidgetChartRenderer';
import { WidgetPlaceholder } from './WidgetPlaceholder';

export interface WidgetProps {
  layout: DashboardLayoutItem[];
  layoutItem: DashboardLayoutItem;
  onLoadedWidget?: (widget: WidgetData) => void;
}

export interface WidgetRef {
  getWidgetData(): WidgetData | undefined;
}

const useWidget = (widgetId: number, onLoadedWidget?: (widget: WidgetData) => void) => {
  const [widget, setWidget] = useState<WidgetData | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    DashboardService.getWidgetData(widgetId, getLanguageId())
      .then((data) => {
        setWidget(data);
        onLoadedWidget?.(data);
      })
      .then(() => setLoading(false));
  }, [widgetId]);

  return { widget, loading };
};

export const Widget = forwardRef<WidgetRef, WidgetProps>((props, ref) => {
  const { layoutItem, layout, onLoadedWidget, ...rest } = props;

  const { widget, loading } = useWidget(layoutItem.widgetId, onLoadedWidget);

  const { trackEvent } = useTracking({ module: Modules.Dashboards });

  const translator = useTranslation();

  const { showUpgradeOWMessage } = useShowUpgradeOWMessage({
    onClickUpgradeToOWLink: () => {
      trackEvent(TrackingEvent.Dashboards.UpgradeToOnixWorkClick);
    },
  });

  const widgetCss = DashboardHelper.calculateWidgetWidth(layoutItem);

  let seriesClick: WidgetChartRendererProps['onSeriesClick'] = (ev) => {
    trackEvent(TrackingEvent.Dashboards.WidgetClick, { widgetId: layoutItem.widgetId });
    showUpgradeOWMessage();
  };

  const widgetData = useMemo(() => (widget ? WidgetHelper.parseWidgetData(layoutItem.widgetId, widget, translator) : undefined), [widget]);

  const chartOptions: WidgetChartRendererProps['chartOptions'] = useMemo(() => {
    if (!widgetData) {
      return {};
    }

    switch (widgetData.widgetTemplateId) {
      case WidgetTemplateId.EquipmentFromView:
      case WidgetTemplateId.JobFromView:
        return WidgetHelper.getEquipmentFromViewChartOptions(widgetData);

      case WidgetTemplateId.IssuesByStatus:
        return WidgetHelper.getIssuesByStatusChartOptions(widgetData);

      default:
        // TODO(knta): handle unknown widget type
        return {};
    }
  }, [widgetData]);

  if (!widget || loading) {
    return <WidgetPlaceholder widgetSize={widgetCss} />;
  }

  return (
    <WidgetChartRenderer
      chartOptions={chartOptions}
      hasData={true}
      isLoading={false}
      widgetCss={widgetCss}
      widget={widgetData}
      onSeriesClick={seriesClick}
    />
  );
});
