import { IPersonaProps, PersonaSize } from '@fluentui/react';

export const colors = {
  grey10: '#FAF9F8',
  grey30: '#EDEBE9',
  grey50: '#D2D0CE',
  grey90: '#a19f9d',
  grey110: '#8a8886',
  grey130: '#605e5c',
  grey160: '#323130',
  grey190: '#201f1e',
  primarypurple: '#8D398E',
};

export const images = {
  base64: 'data:image/jpeg;base64',
  defaultLogo: 'https://onixwork.com/prod/Content/Images/onixExpress.png',
};

export const layers = {
  loadingSpinner: 1000001,
};

export const COLUMN_SIZE = {
  MINI: 90,
  SMALL: 120,
  MEDIUM: 170,
  BIG: 250,
};

export const MaximumInvitationUser = 25;

export const KeyBoardCodes = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
};

export const DefaultPersona: IPersonaProps = {
  hidePersonaDetails: true,
  size: PersonaSize.size32,
  initialsColor: 'hsl(0, 0%, 90%)',
};

export const UpgradeToOnixWorkConstants = {
  wrapperClassName: 'upgrade-to-onix-work',
};
